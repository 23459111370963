import React from 'react';

import { token } from '@atlaskit/tokens';
import CalendarIconCore from '@atlaskit/icon/core/calendar';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

export const CalendarIcon = ({ isSelected }: { isSelected?: boolean }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<CalendarIconCore
			spacing="spacious"
			color={isSelected ? token('color.icon.selected', '#0c66e4') : token('color.icon', '#42526E')}
			{...ssrPlaceholderIdProp}
			label=""
		/>
	);
};
